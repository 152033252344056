import { useState, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const useBrands = () => {
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brandsError, setBrandsError] = useState("");

  const fetchBrands = async () => {
    try {
      setBrandsLoading(true);
      const response = await axios.get(API_URL+'/brands?_sort=order:ASC');

      const brandsList = _.map(response.data, (brand) => {
        const categories = _.map(brand.categories, (category) => {
          return {
            name: category.name
          }
        });

        return {
          name: brand.name,
          categories: categories
        }
      });

      setBrands(brandsList);
      setBrandsLoading(false);
    } catch (error) {
      setBrandsError(error);
    }
  };

  return [{ brands, brandsLoading, brandsError }, useCallback(fetchBrands, [])];
}

export default useBrands;