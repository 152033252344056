import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import * as types from '../types';

import AccountDetail from '../components/signup/AccountDetail';
import BillingAddress from '../components/signup/BillingAddress';
import ShippingAddress from '../components/signup/ShippingAddress';
import ErrorsList from '../components/signup/ErrorsList';

import { createAddress, createUser, createCompany, authUser } from '../actions/auth';
import { sendEmail } from '../actions/cart';
import { updateActivePage } from '../actions/header';

const { REACT_APP_API_URL } = process.env;

const SignupContainer = ({ dispatch }) => {
  const history = useHistory();
  const { register, watch, errors, handleSubmit, setError } = useForm();
  const [showBillingAddress, setShowBillingAddrress] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateActivePage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    if (data.billing_is_shipping) {
      data.billing_street = data.shipping_street;
      data.billing_city = data.shipping_city;
      data.billing_fax_number = data.shipping_fax_number;
      data.billing_phone_number = data.shipping_phone_number;
      data.billing_postal_code = data.shipping_postal_code;
      data.billing_province = data.shipping_province;
    }
    dispatch({ type: types.AUTH_PENDING });
    setLoading(true);

    try {
      const address = await createAddress(data);
      const company = await createCompany(data);
      const user = await createUser(data, address, company);

      // email Dotline and User here
      const dotlineEmailSubject = 'Dotline web user is waiting for your approval';
      const dotlineEmailMessage = `
        <p>${data.email} is waiting for your approval.</p>

        <p>
          <div>
            <span><b>Name: </b></span>
            <span>${data.firstname} ${data.lastname}</span>
          </div>

          <div>
            <span><b>Dotline Sales Rep: </b></span>
            <span>${data.dotline_sales_rep}</span>
          </div>

          <div>
            <span><b>Company: </b></span>
            <span>${data.companyname}</span>
          </div>

          <div>
            <span><b>GST #: </b></span>
            <span>${data.gst_number}</span>
          </div>

          <div>
            <span><b>Years In Business: </b></span>
            <span>${data.years_in_business}</span>
          </div>
        </p>

        <p>
          <div><b>Shipping Address</b></div>
          <div>${data.shipping_street}, ${data.shipping_city} ${data.shipping_province} ${data.shipping_postal_code}</div>
          <div><b>Phone #:</b> ${data.shipping_phone_number}</div>
          <div><b>Fax #:</b> ${data.shipping_fax_number}</div>
        <p>

        <p>
          <div><b>Billing Address</b></div>
          <div>${data.billing_street}, ${data.billing_city} ${data.billing_province} ${data.billing_postal_code}</div>
          <div><b>Phone #:</b> ${data.billing_phone_number}</div>
          <div><b>Fax #:</b> ${data.billing_fax_number}</div>
        <p>

        <p>Click <a href="${REACT_APP_API_URL}/admin/plugins/content-manager/collectionType/plugins::users-permissions.user/${user?.user?.id}">HERE</a> to enable this account.</p>
        `;
      if (user) {
        await sendEmail(user.jwt, dotlineEmailSubject, dotlineEmailMessage, 'customerservice@dotlinedesign.com');

        const userEmailSubject = 'Dotline Design registration request has been received';
        const userEmailMessage = 'Your registration request has been received and currently under review. Your access may be restricted until it is approved.';
        await sendEmail(user.jwt, userEmailSubject, userEmailMessage, data.email);

        dispatch(authUser(user));
        history.push("/");
      }
    } catch (error) {
      if (error.message === "Auth.form.error.email.taken") {
        setError("email", { type: "manual", message: "Email already taken" });
      }
      setLoading(false);
      dispatch({ type: types.AUTH_FAILED });
    }
  }

  return (
    <div className="ui centered middle aligned grid container">
      <div className={"ui inverted dimmer" + (loading ? " active" : "")}>
        <div className="ui text loader">Loading</div>
      </div>
      <div className="row">
        <div className="column">
          <form onSubmit={handleSubmit(onSubmit)} className="ui large form segment">

            <AccountDetail register={register} watch={watch} errors={errors} />

            <ShippingAddress register={register} errors={errors} />

            <div className="field">
              <div className="fields">
                <div className="twelve wide field">
                  <div className="ui checkbox">
                    <input name="billing_is_shipping" type="checkbox" ref={register} onChange={(e) => {setShowBillingAddrress(!e.target.checked)}} />
                    <label>Billing address is same as shipping address</label>
                  </div>
                </div>
              </div>
            </div>

            { showBillingAddress ? <BillingAddress register={register} errors={errors} /> : null }

            <button disabled={loading} type="submit" className="ui large black submit button">Complete Registration</button>
          </form>

          {_.isEmpty(errors) ? null : <ErrorsList errors={errors} customErrors />}

          <div className="ui message">
            Already have an account? <a href="/login">Login</a>
          </div>
        </div>
      </div>
      <div className="ui hidden divider" />
    </div>
  )
};

export default connect()(SignupContainer);
