import React from 'react';
import { useForm } from 'react-hook-form';

const ProductSearch = ({ fetchProducts, setPageFilter }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const filter = `name_contains=${data.search_product}`;
    setPageFilter(`name_contains=${data.search_product}`);
    fetchProducts(filter);
  };

  return (
    <div className="ui grid">
      <div className="sixteen wide column">
        <form onSubmit={handleSubmit(onSubmit)} className="ui form">
          <div className="fields" style={{float: 'right', marginBottom:'0'}}>
            <div className="field">
              <div className="ui search">
                <div className="ui icon input">
                  <input
                    className="prompt"
                    type="text"
                    placeholder="Search products"
                    name="search_product"
                    ref={register}
                  />
                  <i className="search icon"></i>
                </div>
              </div>
            </div>

            <div className="field">
              <button type="submit" className="ui black basic button">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
};

export default ProductSearch;
