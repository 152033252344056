import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { updateActivePage } from '../actions/header'
import BlockedMessage from '../components/BlockedMessage';

const mapDispatchToProps = dispatch => {
  return {
    updateActivePage: () => dispatch(updateActivePage())
  };
}

const BlockedContainer = props => {
  const { updateActivePage } = props;

  useEffect(() => {
    updateActivePage();
  });

  return (
    <BlockedMessage />
  );
}

export default connect(null, mapDispatchToProps)(BlockedContainer);