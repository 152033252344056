import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { Form } from 'semantic-ui-react'

let ResetPasswordForm = ({ handleSubmit, loading }) => {
  return (
    <Form loading={loading} onSubmit={handleSubmit} className="ui large form">
      <div className="ui segment">
        <div className="field">
          <div className="field">
            <div className="ui left icon input">
              <i className="lock icon"></i>
              <Field name="password" component="input" type="password" placeholder="Password"/>
            </div>
          </div>

          <div className="field">
            <div className="ui left icon input">
              <i className="lock icon"></i>
              <Field name="confirm_password" component="input" type="password" placeholder="Confirm Password"/>
            </div>
          </div>
        </div>

        <button disabled={loading} type="submit" className="ui fluid large black submit button">Reset Password</button>
      </div>
    </Form>
  )
}

export default reduxForm({ form: 'ResetPasswordForm' })(ResetPasswordForm);
