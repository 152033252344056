import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoginContainer from '../containers/Login';
import SignupContainer from '../containers/Signup';
import ProductContainer from '../containers/Product2';
import CatalogueContainer from '../containers/Catalogue';
import CartContainer from '../containers/Cart';
import HeaderContainer from '../containers/Header';
import HomeContainer from '../containers/Home';
import FooterComponent from '../components/Footer';
import BlockedContainer from '../containers/Blocked';
import BrandContainer from '../containers/Brand';
import ProfileContainer from '../containers/Profile';
import ContactUsContainer from '../containers/ContactUs';
import ForgotPasswordContainer from '../containers/ForgotPassword';
import ResetPasswordContainer from '../containers/ResetPassword';

import AuthRoute from '../containers/Auth';

const App = () => {
  return (
    // <div style={{ display:"flex", minHeight:"100vh", flexDirection:"column" }}>
    <div style={{ minHeight:'100vh'}}>
      <BrowserRouter>

        <HeaderContainer />

        <div style={{minHeight: '70vh'}}>
          <Switch>
            <Route exact path="/" component={HomeContainer} />

            <Route path="/blocked" component={BlockedContainer} />

            <Route path="/products" component={ProductContainer} />

            <Route path="/brands" component={BrandContainer} />

            <Route path="/contactus" component={ContactUsContainer} />

            <AuthRoute path="/cart" type="private">
              <CartContainer />
            </AuthRoute>

            <AuthRoute path="/catalogue" type="private">
              <CatalogueContainer />
            </AuthRoute>

            <AuthRoute path="/profile" type="private">
              <ProfileContainer />
            </AuthRoute>

            <AuthRoute path="/login" type="guest">
              <LoginContainer />
            </AuthRoute>

            <AuthRoute path="/signup" type="guest">
              <SignupContainer />
            </AuthRoute>

            <AuthRoute path="/forgotpassword" type="guest">
              <ForgotPasswordContainer />
            </AuthRoute>

            <AuthRoute path="/resetpassword" type="guest">
              <ResetPasswordContainer />
            </AuthRoute>

          </Switch>
        </div>

        <FooterComponent />

      </BrowserRouter>
    </div>
  );
};

export default App;
