import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BannerCarousel from '../components/BannerCarousel';

import useCategories from '../hooks/useCategories';
import useBanner from '../hooks/useBanner';

import { updateActivePage } from '../actions/header';

const cardStyle = {
  height: '200px',
  alignItems: 'center',
  justifyContent: 'center',
  textAligh: 'center',
  boxShadow: 'none',
  fontColor: 'black',
  fontSize: '30px'
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: id => dispatch(updateActivePage(id))
  }
};

const HomeContainer = props => {
  const { updateActivePage } = props;

  const [{ categories, categoriesLoading }, fetchCategories] = useCategories();
  const [{ images, imagesLoading }, fetchBannerImages] = useBanner();

  useEffect(() => {
    updateActivePage(1);

    fetchBannerImages();
    fetchCategories();

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return categoriesLoading || imagesLoading ? (
    <div className="ui container">
      <div className="ui active inverted dimmer">
        <div className="ui text loader">Loading</div>
      </div>
    </div>
  ) :
  (
    <div className="ui grid container">
      <div className="sixteen wide column">
        <BannerCarousel images={images} />
      </div>

      <div className="ui hidden divider" />

      <div className="sixteen wide column">
        <div className="ui three stackable centered link cards">
          {
            categories.map((category, index) => {
              return (
                <a key={index} className="ui centered card" style={cardStyle} href={`/products?category=${category.name}`}>
                  <div className="ui image" style={{backgroundColor: 'white'}} >
                  {
                    category.image_url ? <img className="content" src={category.image_url} alt="N/A"/> : <div>{category.name}</div>
                  }
                  </div>
                </a>
              )
            })
          }
        </div>
      </div>

      <div className="ui hidden divider" />
    </div>
  );
}

export default connect(null, mapDispatchToProps)(HomeContainer);