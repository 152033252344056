import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { updateActivePage } from '../actions/header'
import { requestCatalogues } from '../actions/catalogue';

import CatalogueCard from '../components/catalogue/Card';

const mapStateToProps = state => {
  return {
    isPending: state.cataloguesPage.isPending,
    catalogues: state.cataloguesPage.catalogues.data,
    error: state.cataloguesPage.error,
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: (index) => dispatch(updateActivePage(index)),
    requestCatalogues: (token) => dispatch(requestCatalogues(token))
  };
}

const containerStyle = {
  minHeight: '700px'
}

class CatalogueContainer extends Component {
  componentDidMount() {
    this.props.updateActivePage(4);
    this.props.requestCatalogues(this.props.token);
  }

  render() {
    const { catalogues, isPending } = this.props;

    return isPending ?
    (
      <div className="ui active dimmer">
        <div className="ui text loader">Loading</div>
      </div>
    ) :
    (
      <div style={containerStyle} className="ui four column grid container">
        {
          _.map(catalogues, catalogue => {
            return (
              <div key={catalogue.id} className="column">
                <CatalogueCard catalogue={catalogue} />
              </div>
            );
          })
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogueContainer);
