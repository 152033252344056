import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { requestForgotPassword, resetAuth } from '../actions/auth';
import { updateActivePage } from '../actions/header'
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const mapDispatchToProps = dispatch => {
  return {
    requestForgotPassword: (formData) => dispatch(requestForgotPassword(formData)),
    updateActivePage: id => dispatch(updateActivePage(id)),
    resetAuth: () => dispatch(resetAuth())
  };
}

const mapStateToProps = state => {
  return {
    authPending: state.auth.pending,
    authError: state.auth.error,
    requestFinished: state.auth.forgot_password_done
  };
}

const ForgotPasswordContainer = (props) => {
  const {requestForgotPassword, authPending, updateActivePage, resetAuth, requestFinished } = props;

  useEffect(() => {
    updateActivePage(0);
    resetAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui centered middle aligned three column grid container">
      <div className="row">
        <div className="column">
          <ForgotPasswordForm loading={authPending} finished={requestFinished} onSubmit={requestForgotPassword} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
