import * as types from '../types';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const addItem = (item) => (dispatch) => {
  dispatch({
    type: types.CART_ADD_ITEM,
    payload: item
  })
};

export const updateItem = (item) => (dispatch) => {
  dispatch({
    type: types.CART_UPDATE_ITEM,
    payload: item
  })
};

export const removeItem = (item) => (dispatch) => {
  dispatch({
    type: types.CART_REMOVE_ITEM,
    payload: item
  })
};

export const sendEmail = async (token, subject, message, to) => {
  try {
    await axios.post(
      API_URL+'/email',
      {
        to: to,
        html: message,
        from: 'customerservice@dotlinedesign.com',
        subject: subject
      }, {
        headers: { Authorization: `Bearer ${token}`}
      });

    return { type: types.EMAIL_SUCCESS };
  } catch (error) {
    return { type: types.EMAIL_FAILED, payload: error};
  }
};