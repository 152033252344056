import React from 'react';

const Footer = () => {
  return (
    <div className="ui inverted vertical footer segment">
      <div className="ui container">
        <div className="ui stackable inverted divided equal height stackable grid">
          <div className="six wide column">
            <h4 className="ui inverted header">Contact Us</h4>
            <div className="ui inverted list">
              <p>255 Shields Court Unit C, Markham, ON L3R 8V2</p>
              <p>Tel: 1-905-760-1133</p>
              <p>Email: customerservice@dotlinedesign.com</p>
            </div>
          </div>
          <div className="ten wide column">
            <h4 className="ui inverted header">Dotline Design</h4>
            <h4 className="ui inverted header">Return Policy</h4>
            <p>
              Dot-Line Design unconditionally guarantee’s your satisfaction.
              Please call customer service at 1-905-760-1133 for your return authorization number
              if you are not satisfied with our product. Returns can not be accepted without authorization.
              Returns can not be accepted after 30 days.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;