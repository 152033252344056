import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'semantic-ui-react';
import queryString from 'query-string';

import useCategories from '../hooks/useCategories';
import useProducts from '../hooks/useProducts';
import { updateActivePage } from '../actions/header';
import { addItem } from '../actions/cart';
import generateFilerString from '../actions/filter';

import ProductMenu from '../components/product/Menu';
import ProductList from '../components/product/List';
import ProductSearch from '../components/product/Search';

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    blocked: state.auth.blocked,
    isLoggedIn: state.auth.isLoggedIn
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: (index) => dispatch(updateActivePage(index)),
    addItem: (item) => dispatch(addItem(item))
  };
};

const ProductContainer = (props) => {
  const { updateActivePage, blocked, isLoggedIn, addItem } = props;
  const query = queryString.parse(props.location.search);

  const [activeProductsPage, setActiveProductsPage] = useState(1);
  const [pageFilter, setPageFilter] = useState("");

  const [{ categories, categoriesLoading }, fetchCategories] = useCategories();
  const [{ products, productsCount, productsLoading }, fetchProducts] = useProducts();

  const onProductsPageChange = (e, pageInfo) => {
    setActiveProductsPage(pageInfo.activePage);
    fetchProducts(pageFilter, pageInfo.activePage);
  };

  const onMenuChange = (filter='') => {
    fetchProducts(filter);
    setActiveProductsPage(1);
    setPageFilter(filter);
  };

  useEffect(() => {
    updateActivePage(2);

    fetchCategories();

    let initialFilter = generateFilerString(query);
    fetchProducts(initialFilter);
    setPageFilter(initialFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (categoriesLoading && productsLoading) ?
  (
    <div className="ui active inverted dimmer">
      <div className="ui text loader">Loading</div>
    </div>
  ) :
  (
    <div className="ui container">
      <ProductSearch fetchProducts={fetchProducts} setPageFilter={setPageFilter} />

      <div className="ui section divider" />

      <div className="ui grid">
        <div className="four wide column">
          <ProductMenu categories={categories} query={query} onMenuChange={onMenuChange} />
        </div>
        <div className="twelve wide column">
          <ProductList isLoggedIn={isLoggedIn} products={products} addItem={addItem} blocked={blocked} loading={productsLoading} />
        </div>
      </div>

      <div className="ui section divider" />

      <div className="ui grid">
        <div className="ui centered row">
          <Pagination
            activePage={activeProductsPage}
            onPageChange={onProductsPageChange}
            siblingRange={1}
            totalPages={Math.ceil(productsCount / 12)}
          />
        </div>
      </div>

      <div className="ui hidden divider" />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);