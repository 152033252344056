import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react';

import { removeItem, addItem, sendEmail } from '../actions/cart';
import { updateActivePage } from '../actions/header'

import CartList from '../components/cart/List';
import CartHeader from '../components/cart/Header';
import * as types from '../types';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const mapStateToProps = state => {
  return {
    cart: state.cart,
    cartItems: state.cart.items,
    cartTotal: formatter.format(state.cart.total),
    user: state.auth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: item => dispatch(removeItem(item)),
    addItem: item => dispatch(addItem(item)),
    updateActivePage: id => dispatch(updateActivePage(id)),
    resetCart: () => dispatch({ type: types.CART_RESET })
  }
};

const CartContainer = (props) => {
  const { updateActivePage, user, cart, removeItem, addItem, resetCart } = props;

  useEffect(() => {
    updateActivePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CartHeader user={user} cart={cart} sendOrder={sendEmail} resetCart={resetCart} />

      <Divider />
      <CartList items={cart.items} removeItem={removeItem} addItem={addItem} />
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);