export const REQUEST_PRODUCTS_PENDING = 'REQUEST_PRODUCTS_PENDING';
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS';
export const REQUEST_PRODUCTS_FAILED = 'REQUEST_PRODUCTS_FAILED';
export const PRODUCT_RESET = 'PRODUCT_RESET';

export const REQUEST_CATALOGUES_PENDING = 'REQUEST_CATALOGUES_PENDING'
export const REQUEST_CATALOGUES_SUCCESS = 'REQUEST_CATALOGUES_SUCCESS';
export const REQUEST_CATALOGUES_FAILED = 'REQUEST_CATALOGUES_FAILED';
export const CATALOGUE_RESET = 'CATALOGUE_RESET';

export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT';
export const PRODUCT_APPLY_FILTER = 'APPLY_FILTER';

const UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_PENDING = 'AUTH_PENDING';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_RESET = 'AUTH_RESET';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const REQUEST_REGISTER_SUCCESS = 'REQUEST_REGISTER_SUCCESS';
export const REQUEST_FORGOT_PASSWORD_SUCCESS = 'REQUEST_FORGOT_PASSWORD_SUCCESS';
export const REQUEST_FORGOT_PASSWORD_FAILED = 'REQUEST_FORGOT_PASSWORD_FAILED';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILED = 'REQUEST_RESET_PASSWORD_FAILED';

export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS';
export const REQUEST_CATEGORIES_FAILED = 'REQUEST_CATEGORIES_FAILED';
export const CATEGORIES_RESET = 'CATEGORIES_RESET';

export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_RESET = 'CART_RESET';

export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAILED = 'EMAIL_FAILED';

export const REQUEST_BANNER_PENDING = 'REQUEST_BANNER_PENDING';
export const REQUEST_BANNER_SUCCESS = 'REQUEST_BANNER_SUCCESS';
export const REQUEST_BANNER_FAILED = 'REQUEST_BANNER_FAILED';


export default {
  UPDATE_ACTIVE_PAGE
}