import { useState, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const useBanner = () => {
  const [images, setImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imagesError, setImagesError] = useState("");


  const fetchBannerImages = async () => {
    try {
      setImagesLoading(true);
      const response = await axios.get(API_URL+'/main-banner');

      const imagesList = _.map(response.data?.banner_images, (banner) => {
        return { url: banner?.image?.url };
      });

      setImages(imagesList);
      setImagesLoading(false);
    } catch (error) {
      setImagesError(error);
    }
  };

  return [{ images, imagesLoading, imagesError}, useCallback(fetchBannerImages, [])];
};

export default useBanner;