import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import { PersistGate } from 'redux-persist/integration/react'
import storageSession from 'redux-persist/lib/storage/session'

import thunkMiddleware from 'redux-thunk';

import App from './app/App';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  transforms: [
    expireReducer('productsPage', { expireSeconds: 3600 }),
    expireReducer('cataloguesPage', { expireSeconds: 3600 }),
    expireReducer('activePage', { expireSeconds: 3600 }),
    expireReducer('auth', { expireSeconds: 3600 }),
    expireReducer('categories', { expireSeconds: 3600 }),
    expireReducer('cart', { expireSeconds: 3600 }),
    expireReducer('banner', { expireSeconds: 3600 }),
    expireReducer('form', { expireSeconds: 3600 })
  ]
}

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}
middlewares.push(thunkMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.querySelector('#root')
);
