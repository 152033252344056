import * as types from '../types';
import _ from 'lodash';

const initialStateCart = {
  items: [],
  total: 0
}

export const cart = (state=initialStateCart, action={}) => {
  let total = Number(state.total);

  switch(action.type) {
    case types.CART_ADD_ITEM:
      let addNewItems = [...state.items];

      const addItemPrice = Number.parseFloat(action.payload.price);
      const addItemQuantity = Number.parseInt(action.payload.quantity);
      const indexFound = _.findIndex(addNewItems, item => {
        return item.name === action.payload.name &&
          item.description === action.payload.description &&
          item.size === action.payload.size &&
          item.color === action.payload.color &&
          item.price === action.payload.price
      });
      if (indexFound > -1) {
        let removedItem = addNewItems.splice(indexFound, 1)[0];
        let removedItemQuantity = Number(removedItem.quantity);
        total -= parseFloat(Number(addItemPrice * removedItemQuantity).toFixed(2));
      }

      addNewItems.push(action.payload);
      addNewItems = _.orderBy(addNewItems, ['id', 'size', 'color'],['asc', 'asc', 'asc'])

      total += parseFloat(Number(addItemPrice * addItemQuantity).toFixed(2));
      return { items: addNewItems, total: Number(total.toFixed(2)) };
    case types.CART_REMOVE_ITEM:
      let removeNewItems = [...state.items];

      const removeItemPrice = Number.parseFloat(action.payload.price);
      const removeItemQuantity = Number.parseInt(action.payload.quantity);

      let index = _.findIndex(removeNewItems, (item) => _.isEqual(item, action.payload));
      if (index > -1) {
        removeNewItems.splice(index, 1);
        total -= parseFloat(removeItemPrice * removeItemQuantity);
      }

      return { items: removeNewItems, total: Number(total.toFixed(2)) };
    case types.CART_RESET:
      return { items: [], total: 0 };
    default:
      return state;
  }
};