import { useState, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState("");

  const fetchCategories = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(API_URL+'/categories?_sort=order:ASC');

      const categoriesList = _.map(response.data, (category) => {
        const subcategories = _.map(category.subcategories, (subcategory) => {
          return {
            name: subcategory.name
          }
        });

        return {
          name: category.name,
          image_url: category?.image?.url,
          subcategories: subcategories
        }
      });

      setCategories(categoriesList);
      setCategoriesLoading(false);
    } catch (error) {
      setCategoriesError(error);
    }
  };

  return [{ categories, categoriesLoading, categoriesError }, useCallback(fetchCategories, [])];
}

export default useCategories;