const generateFilerString = (query) => {
  if (query?.brand) {
    if (query?.category) {
      return (`brand.name=${query.brand}&category.name=${query.category}`);
    } else {
      return (`brand.name=${query.brand}`);
    }
  } else if (query?.category) {
    if (query?.subcategory) {
      return (`category.name=${query.category}&subcategory.name=${query.subcategory}`);
    } else {
      return (`category.name=${query.category}`);
    }
  }
  return '';
};

export default generateFilerString;