import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { Form } from 'semantic-ui-react'

let ForgotPasswordForm = ({handleSubmit, loading, finished}) => {
  let message = finished ? 'Email Sent!' : 'Request Password Reset'

  return (
    <Form loading={loading} error={finished} onSubmit={handleSubmit} className="ui large form">
      <div className="ui segment">
        <div className="field">
          <div className="ui left icon input">
            <i className="user icon"></i>
            <Field name="email" component="input" type="text" placeholder="E-mail address" />
          </div>
        </div>

        <button disabled={loading === true || finished === true} type="submit" className="ui fluid large black submit button">{message}</button>
      </div>
    </Form>
  )
}

export default reduxForm({ form: 'ForgotPasswordForm' })(ForgotPasswordForm);
