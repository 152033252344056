import React from 'react';

const BillingAddress = ({ register, errors }) => {

  return (
    <div>
      <div className="ui hidden divider" />
      <h4 className="ui horizontal divider header">Billing Address</h4>
      <div className={`field ${errors.billing_street ? " error" : ""}`}>
        <label>Street Address</label>
        <input name="billing_street" type="text" placeholder="123 Example Rd." ref={register({ required: 'Billing address is required.' })} />
      </div>
      <div className="three fields">
        <div className={`field ${errors.billing_city ? " error" : ""}`}>
          <label>City</label>
          <div className="ui left input">
            <input name="billing_city" type="text" placeholder="City" ref={register({ required: 'Billing city required.' })} />
          </div>
        </div>
        <div className={`field ${errors.billing_province ? " error" : ""}`}>
          <label>Province</label>
          <div className="ui left input">
            <input name="billing_province" type="text" placeholder="Province" ref={register({ required: 'Billing province required.' })} />
          </div>
        </div>
        <div className={`field ${errors.billing_postal_code ? " error" : ""}`}>
          <label>Postal Code</label>
          <div className="ui left input">
            <input name="billing_postal_code" type="text" placeholder="Postal Code" ref={register({ required: 'Billing postal code required.' })} />
          </div>
        </div>
      </div>
      <div className="two fields">
        <div className={`field ${errors.billing_phone_number ? " error" : ""}`}>
          <label>Phone #</label>
          <div className="ui left input">
            <input name="billing_phone_number" type="text" placeholder="Phone #" ref={register({ required: 'Billing phone # required.' })} />
          </div>
        </div>
        <div className={`field ${errors.billing_fax_number ? " error" : ""}`}>
          <label>Fax #</label>
          <div className="ui left input">
            <input name="billing_fax_number" type="text" placeholder="Fax #" ref={register} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;