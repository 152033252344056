import * as types from '../types';
import axios from 'axios';
import _ from 'lodash';

const {REACT_APP_API_URL} = process.env;

export const resetAuth = () => {
  return { type: types.AUTH_RESET };
};

export const requestLogin = (formData) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });

  axios.post(REACT_APP_API_URL+'/auth/local', {
    identifier: formData.email,
    password: formData.password
  })
    .then(response => {
      dispatch({
        type: types.AUTH_SUCCESS,
        payload: {
          id: response.data.user.id,
          token: response.data.jwt,
          username: response.data.user.email,
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          email: response.data.user.email,
          dotline_sales_rep: response.data.user.dotline_sales_rep,
          billing_address: response.data.user?.address?.billing_address,
          billing_phone_number: response.data.user?.address?.billing_address?.phone_no,
          billing_fax_number: response.data.user?.address?.billing_address?.fax_no,
          shipping_address: response.data.user?.address?.shipping_address,
          shipping_phone_number: response.data.user?.address?.shipping_address?.phone_no,
          shipping_fax_number: response.data.user?.address?.shipping_address?.fax_no,
          company: {
            name: response.data.user?.company?.name,
            gst_number: response.data.user?.company?.gst_number
          },
          blocked: !response.data.user?.account_enabled
        }
      });
    })
    .catch(error => dispatch({
      type: types.AUTH_FAILED,
      payload: error
    }));
};

export const requestRegister = (formData) => (dispatch) => {
  axios.post(REACT_APP_API_URL+'/auth/local/register', {
    username: formData.email,
    email: formData.email,
    password: formData.password,
    account_blocked: true
  })
    .then(response => {
      dispatch({
      type: types.REQUEST_REGISTER_SUCCESS,
      payload: response.data.jwt
    })})
    .catch(error => {
      dispatch({
      type: types.AUTH_FAILED,
      payload: error
    })});
};

export const logout = () => (dispatch) => {
  dispatch({ type: types.CART_RESET });

  return dispatch({ type: types.LOGOUT_SUCCESS });
};

export const createAddress = async (formData) => {
  try {
    const response = await axios.post(
      REACT_APP_API_URL+'/addresses',
      {
        billing_address: {
          street: formData.billing_street,
          city: formData.billing_city,
          province: formData.billing_province,
          postal_code: formData.billing_postal_code,
          phone_no: formData.billing_phone_number,
          fax_no: formData.billing_fax_number
        },
        shipping_address: {
          street: formData.shipping_street,
          city: formData.shipping_city,
          province: formData.shipping_province,
          postal_code: formData.shipping_postal_code,
          phone_no: formData.shipping_phone_number,
          fax_no: formData.shipping_fax_number
        }
      }
    );

    return response.data;
  } catch(error) {
    console.log(error);
  }
};


export const createCompany = async (formData) => {
  try {
    const response = await axios.post(
      REACT_APP_API_URL+'/companies',
      {
        name: formData.companyname,
        gst_number: formData.gst_number,
        years_in_business: formData.years_in_business
      }
    );

    return response.data;
  } catch(error) {
    console.log(error);
  }
}

export const createUser = async (formData, address, company) => {
  try {
    const response = await axios.post(
      REACT_APP_API_URL+'/auth/local/register',
      {
        username: formData.email,
        email: formData.email,
        firstname: formData.firstname,
        lastname: formData.lastname,
        password: formData.password,
        dotline_sales_rep: formData.dotline_sales_rep,
        address: address.id,
        company: company.id,
        account_enabled: false
      }
    );

    return response.data;
  } catch (error) {
    const emailTakenError = _.find(error.response.data.message, (messageList) => {
      return _.find(messageList.messages, (message) => message.id === 'Auth.form.error.email.taken')
    });

    if (emailTakenError) {
      throw new Error("Auth.form.error.email.taken");
    }
  }
};

export const authUser = (data) => {
  return {
    type: types.AUTH_SUCCESS,
    payload: {
      id: data.user.id,
      token: data.jwt,
      username: data.user.email,
      firstname: data.user.firstname,
      lastname: data.user.lastname,
      email: data.user.email,
      dotline_sales_rep: data.user.dotline_sales_rep,
      billing_address: data.user?.address.billing_address,
      billing_phone_number: data.user?.address.billing_address?.phone_no,
      billing_fax_number: data.user?.address.billing_address?.fax_no,
      shipping_address: data.user?.address?.shipping_address,
      shipping_phone_number: data.user?.address.shipping_address?.phone_no,
      shipping_fax_number: data.user?.address.shipping_address?.fax_no,
      company: {
        name: data.user?.company.name,
        gst_number: data.user?.company.gst_number
      },
      blocked: !data.user?.account_enabled
    }
  }
};

export const requestForgotPassword = (formData) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });

  axios.post(REACT_APP_API_URL+'/auth/forgot-password', {
    email: formData.email,
    url: 'http://www.dotlinedesign.com/resetpassword'
  })
    .then(response => {
      dispatch({ type: types.REQUEST_FORGOT_PASSWORD_SUCCESS });
    })
    .catch(error => {
      dispatch({ type: types.REQUEST_FORGOT_PASSWORD_FAILED });
    });
};

export const requestResetPassword = (formData, token) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });

  // dispatch({ type: types.REQUEST_RESET_PASSWORD_SUCCESS, payload: {user: 'something'} });
  axios.post(REACT_APP_API_URL+'/auth/reset-password', {
    code: token,
    password: formData.password,
    passwordConfirmation: formData.confirm_password
  })
    .then(response => {
      dispatch({
        type: types.REQUEST_RESET_PASSWORD_SUCCESS,
        payload: {
          id: response.data.user.id,
          token: response.data.jwt,
          username: response.data.user.email,
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          email: response.data.user.email,
          dotline_sales_rep: response.data.user.dotline_sales_rep,
          billing_address: response.data.user?.address?.billing_address,
          billing_phone_number: response.data.user?.address?.billing_address?.phone_no,
          billing_fax_number: response.data.user?.address?.billing_address?.fax_no,
          shipping_address: response.data.user?.address?.shipping_address,
          shipping_phone_number: response.data.user?.address?.shipping_address?.phone_no,
          shipping_fax_number: response.data.user?.address?.shipping_address?.fax_no,
          company: {
            name: response.data.user?.company?.name,
            gst_number: response.data.user?.company?.gst_number
          },
          blocked: !response.data.user?.account_enabled
        }
      });
    })
    .catch(error => {
      dispatch({ type: types.REQUEST_RESET_PASSWORD_FAILED });
    });
}
