import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import cataloguesReducer from './catalogue';
import { activePage } from './header';
import { auth } from './auth';
import { cart } from './cart';

export default combineReducers({
  cataloguesPage: cataloguesReducer,
  activePage,
  auth,
  cart,
  form: formReducer
});