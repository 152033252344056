import * as types from '../types';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const requestCatalogues = (token) => (dispatch) => {
  dispatch({ type: types.REQUEST_CATALOGUES_PENDING });

  axios
    .get(API_URL+'/catalogues', { headers: { Authorization: `Bearer ${token}`} })
    .then(response => dispatch({
      type: types.REQUEST_CATALOGUES_SUCCESS,
      payload: response.data
    }))
    .catch(error => dispatch({
      type: types.REQUEST_CATALOGUES_FAILED,
      payload: error
    }));
};
