import React, { useState } from 'react';
import Modal from './Modal';

const imageStyle = {
  height: '200px',
  objectFit: 'contain',
  textAlign: 'center'
}

const imageContainerStyle = {
  height: '200px',
  backgroundColor: 'white'
}

const Card = ({ product, addItem, blocked, isLoggedIn }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
      <div className="ui centered card" onClick={()=> setModalOpen(!modalOpen)}>
        <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          product={product}
          addItem={addItem}
          blocked={blocked}
          isLoggedIn={isLoggedIn}
        />
        {
          product?.image?.url ? (
            <div className="ui centered image" style={imageContainerStyle} >
              <img src={product?.image?.url} style={imageStyle} alt="N/A" />
            </div>
          ) :
          (
            <div className="ui placeholder" style={imageContainerStyle} ><div className="square image" /></div>
          )
        }

        <div className="content">
          <div className="header">{product.name}</div>
          <div className="meta">{product.description}</div>
        </div>
      </div>
  );
};

export default Card;