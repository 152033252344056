import * as types from '../types';

const initialStateCatalogues = {
  isPending: false,
  data: [],
  error: ''
};

const catalogues = (state=initialStateCatalogues, action={}) => {
  switch(action.type) {
    case types.REQUEST_CATALOGUES_PENDING:
      return { ...state, isPending: true };
    case types.REQUEST_CATALOGUES_SUCCESS:
      return { ...state, data: action.payload, isPending: false };
    case types.REQUEST_CATALOGUES_FAILED:
      return { ...state, data: [], error: action.payload, isPending: false };
    case types.CATALOGUE_RESET:
      return initialStateCatalogues;
    default:
      return state;
  }
};

const cataloguesReducer = (state = {}, action) => {
  return {
    catalogues: catalogues(state.catalogues, action),
  }
}

export default cataloguesReducer;
