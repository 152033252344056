import React from 'react';
import _ from 'lodash';

const ErrorsList = ({ errors }) => {
  const list = _.map(errors, (value, key) => {
    return <li key={key}>{value.message}</li>;
  })

  return (
    <div className="ui error message">
      <ul className="list">
        {list}
      </ul>
    </div>
  );
};

export default ErrorsList;