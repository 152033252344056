import React from 'react';
import _ from 'lodash';
import { Container, Button, Image, Grid, Dropdown } from 'semantic-ui-react'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const textStyle = {
  fontSize: '1.2em'
}

const CartList = ({ items, removeItem, addItem }) => {
  const handleQuantityChange = (e, target, item) => {
    addItem({...item, quantity: Number(target.value)});
  }

  return (
    <Container>
      <Grid columns={4} textAlign='center' divided="vertically" verticalAlign='middle'>
      {
        items.map((item, index) => {
          const quantityOptions = _.map(Array.from(Array(20), (_, i) => ((i + 1) * item.quantityPerPack).toString()), quantity => ({
            text: quantity,
            value: quantity
          }));

          return (
            <Grid.Row key={index}>
              <Grid.Column>
                <Image src={item.url} />
              </Grid.Column>
              <Grid.Column textAlign='left' >
                <div className="header" style={textStyle}>{item.name} - {item.description}</div>
                <div style={textStyle}>Price: {formatter.format(item.price)}</div>
                <div style={textStyle}>Size: {item.size}</div>
                <div style={textStyle}>Color: {item.color}</div>
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  onChange={(e, target) => handleQuantityChange(e, target, item)}
                  selection
                  scrolling
                  value={item.quantity.toString()}
                  options={quantityOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <Button onClick={() => removeItem(item)}>Delete</Button>
              </Grid.Column>
            </Grid.Row>
          );
        })
      }
      </Grid>
    </Container>
  );
}

export default CartList;