import * as types from '../types';

const initialStateAuth = {
  isLoggedIn: false,
  username: null,
  email: null,
  token: null,
  pending: false,
  blocked: true,
  error: false,
  forgot_password_done: false,
  billing_address: {},
  shipping_address: {},
  company: {}
}

const parseAddress = (address) => {
  return `${address.street}, ${address.city} ${address.province} ${address.postal_code}`;
};

export const auth = (state=initialStateAuth, action={}) => {
  let token = ''

  switch(action.type) {
    case types.AUTH_SUCCESS:
      token = action.payload.blocked ? '' : action.payload.token;

      return {
        ...state,
        id: action.payload.id,
        isLoggedIn: true,
        token: token,
        username: action.payload.username,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        pending: false,
        blocked: action.payload.blocked,
        error: false,
        email: action.payload.email,
        dotline_sales_rep: action.payload.dotline_sales_rep,
        billing_address: action.payload.billing_address,
        billing_address_text: parseAddress(action.payload.billing_address),
        shipping_address: action.payload.shipping_address,
        shipping_address_text: parseAddress(action.payload.shipping_address),
        company: action.payload.company
      };
    case types.AUTH_FAILED:
      return { ...state, error:true, pending: false };
    case types.AUTH_PENDING:
      return { ...state, pending: true}
    case types.AUTH_RESET:
      return initialStateAuth;
    case types.LOGOUT_SUCCESS:
      return initialStateAuth;
    case types.REQUEST_REGISTER_SUCCESS:
      return {
        ...initialStateAuth,
        isLoggedIn: true,
        pending: false,
        blocked: true
      }
    case types.REQUEST_FORGOT_PASSWORD_SUCCESS:
      return { ...state, pending: false, forgot_password_done: true };
    case types.REQUEST_FORGOT_PASSWORD_FAILED:
      return { ...state, pending: false, forgot_password_done: true };
    case types.REQUEST_RESET_PASSWORD_SUCCESS:
      token = action.payload.blocked ? '' : action.payload.token;

      return {
        ...state,
        id: action.payload.id,
        isLoggedIn: true,
        token: token,
        username: action.payload.username,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        pending: false,
        blocked: action.payload.blocked,
        error: false,
        email: action.payload.email,
        dotline_sales_rep: action.payload.dotline_sales_rep,
        billing_address: action.payload.billing_address,
        billing_address_text: parseAddress(action.payload.billing_address),
        shipping_address: action.payload.shipping_address,
        shipping_address_text: parseAddress(action.payload.shipping_address),
        company: action.payload.company
       };
    case types.REQUEST_RESET_PASSWORD_FAILED:
      return { ...state, error:true, pending: false }
    default:
      return state;
  }
};
