import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Image, Modal, Grid } from 'semantic-ui-react'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const textStyleTotal = {
  fontSize: '1.5em'
}
const textStyle = {
  fontSize: '1.2em'
}

const CartModal = ({ user, cart, sendOrder, resetCart }) => {
  let emailSubject = 'Dotline Design Order Request';
  let userEmailSubject = 'Dotline Design Order Confirmation';
  let emailContent = `
  <p>
    <div>
      <span><b>Name: </b></span>
      <span>${user.firstname} ${user.lastname}</span>
    </div>

    <div>
      <span><b>Email: </b></span>
      <span>${user.email}</span>
    </div>

    <div>
      <span><b>Dotline Sales Rep: </b></span>
      <span>${user.dotline_sales_rep}</span>
    </div>

    <div>
      <span><b>Company: </b></span>
      <span>${user.company.name}</span>
    </div>

    <div>
      <span><b>GST #: </b></span>
      <span>${user.company.gst_number}</span>
    </div>
  </p>

  <p>
    <div>
      <span><b>Shipping Address: </b></span>
      <span>${user.shipping_address_text}</span>
    </div>
    <div>
      <span><b>Phone #: </b></span>
      <span>${user.shipping_address.phone_no}</span>
    </div>
    <div>
      <span><b>Fax #: </b></span>
      <span>${user.shipping_address.fax_no}</span>
    </div>
  </p>

  <p>
    <div>
      <span><b>Billing Address: </b></span>
      <span>${user.billing_address_text}</span>
    </div>
    <div>
      <span><b>Phone #: </b></span>
      <span>${user.billing_address.phone_no}</span>
    </div>
    <div>
      <span><b>Fax #: </b></span>
      <span>${user.billing_address.fax_no}</span>
    </div>
  </p>

  <br />

  <table style="width:100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th>Product</th>
        <th>Color</th>
        <th>Size</th>
        <th>Quantity</th>
      </tr>
    </thead>
    <tbody>
  `;

  _.forEach(cart.items, item => {
    emailContent += `
      <tr style="border-top: 1px solid black;border-bottom: 1px solid black;">
        <td style='text-align:left;vertical-align:middle'>${item.name} - ${item.description}</td>
        <td style='text-align:center;vertical-align:middle'>${item.color}</td>
        <td style='text-align:center;vertical-align:middle'>${item.size}</td>
        <td style='text-align:center;vertical-align:middle'>${item.quantity}</td>
      </tr>
      `;
  });
  emailContent += `
        <tr>
          <td colspan="4">
            <div style='text-align:right;vertical-align:middle'>Total: ${formatter.format(cart.total)}</div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div style='text-align:right;vertical-align:middle'>Total does not include HST and Shipping</div>
          </td>
        </tr>
      </tbody>
    </table>
    `;

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const sendOrderFromModal = async (emailContent) => {
    await sendOrder(user.token, emailSubject, emailContent, 'customerservice@dotlinedesign.com');
    await sendOrder(user.token, userEmailSubject, emailContent, user.email);
    resetCart();
    closeModal();
  };

  return (
    <Modal
      trigger={<Button primary disabled={cart.items.length < 1} onClick={openModal}>Checkout</Button>}
      closeOnDimmerClick={false}
      size='large'
      closeIcon={true}
      open={modalOpen}
      onClose={closeModal}
    >
      <Modal.Header>Confirm your order {user.username}</Modal.Header>
      <Modal.Content scrolling>
        <Grid columns={2} divided="vertically" verticalAlign='middle'>
          {
            cart.items.map((item, i) => {
              return (
                <Grid.Row key={i}>
                  <Grid.Column>
                    <Image centered size='small' src={item.url} />
                  </Grid.Column>
                  <Grid.Column>
                    <div className="header" style={textStyle}>{item.name} - {item.description}</div>
                    <div style={textStyle}>Quantity: {item.quantity}</div>
                    <div style={textStyle}>Price: {formatter.format(item.price)}</div>
                    <div style={textStyle}>Size: {item.size}</div>
                    <div style={textStyle}>Color: {item.color}</div>
                  </Grid.Column>
                </Grid.Row>
              );
            })
          }
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <div className="ui header" style={textStyleTotal}>Total: {formatter.format(cart.total)}</div>
        <h3>Total does not include HST and Shipping</h3>
        <Button negative onClick={closeModal}>Cancel</Button>
        <Button positive onClick={() => sendOrderFromModal(emailContent)}>Send Order</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CartModal;