import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { Form, Message } from 'semantic-ui-react'

let LoginForm = ({handleSubmit, loading, authError}) => {
  return (
    <Form loading={loading} error={authError} onSubmit={handleSubmit} className="ui large form">
      <Message error header="Error" content="Invalid Email/Password"/>
      <div className="ui segment">
        <div className="field">
          <div className="ui left icon input">
            <i className="user icon"></i>
            <Field name="email" component="input" type="text" placeholder="E-mail address" />
          </div>
        </div>

        <div className="field">
          <div className="ui left icon input">
            <i className="lock icon"></i>
            <Field name="password" component="input" type="password" placeholder="Password"/>
          </div>
        </div>
        <button disabled={loading} type="submit" className="ui fluid large black submit button">Login</button>
      </div>
    </Form>
  )
}

export default reduxForm({ form: 'LoginForm' })(LoginForm);
