import { useState, useCallback } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsError, setProductsError] = useState("");

  const fetchProducts = async (filter, activePage=1) => {
    try {
      setProductsLoading(true);
      const countResponse = await axios.get(API_URL+'/products/count?'+filter);
      const count = countResponse.data;

      let startPage = (activePage - 1) * 12;
      const pagination = `&_start=${startPage.toString()}&_limit=12`;
      const response = await axios.get(API_URL+'/products?'+filter+'&_sort=name:ASC'+pagination);

      setProducts(response.data);
      setProductsCount(count);
      setProductsLoading(false);
    } catch (error) {
      setProductsError(error);
    }
  };

  return [{ products, productsCount, productsLoading, productsError }, useCallback(fetchProducts, [])];
}

export default useProducts;