import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { requestResetPassword, resetAuth } from '../actions/auth';
import { updateActivePage } from '../actions/header'
import ResetPasswordForm from '../components/ResetPasswordForm';

const mapDispatchToProps = dispatch => {
  const query = queryString.parse(window.location.search);
  const token = query?.code;

  return {
    requestResetPassword: (formData) => dispatch(requestResetPassword(formData, token)),
    updateActivePage: id => dispatch(updateActivePage(id)),
    resetAuth: () => dispatch(resetAuth())
  };
}

const mapStateToProps = state => {
  return {
    authPending: state.auth.pending,
    authError: state.auth.error
  };
}

const ResetPasswordContainer = (props) => {
  const {requestResetPassword, authPending, updateActivePage, resetAuth } = props;

  useEffect(() => {
    updateActivePage(0);
    resetAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui centered middle aligned three column grid container">
      <div className="row">
        <div className="column">
          <ResetPasswordForm loading={authPending} onSubmit={requestResetPassword} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
