import React from 'react';
import Card from './Card';

const ProductList = ({ products, addItem, blocked, isLoggedIn, loading }) => {
  return loading ?
  (
    <div className="ui active inverted dimmer">
      <div className="ui text loader">Loading</div>
    </div>
  ) :
  (
    <div className="ui container">
      <div className="ui three stackable raised link cards">
      {
        products.map((product) => {
          return (
            <Card
              key={product.id}
              product={product}
              addItem={addItem}
              blocked={blocked}
              isLoggedIn={isLoggedIn}
            />
          );
        })
      }
      </div>
    </div>
  );
}

export default ProductList;