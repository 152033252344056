import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { updateActivePage } from '../actions/header';

const textStyle = {
  fontSize: '1.3em'
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: (index) => dispatch(updateActivePage(index))
  };
};

const ContactUsContainer = (props) => {
  const { updateActivePage } = props;

  useEffect(() => {
    updateActivePage(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui container">
      {/* <div className="ui two column center aligned grid">
        <div className="column"> */}
          <div className="ui center aligned segment">
            <i className="wechat huge icon" />
            <h2 className="ui header">Contact Customer Support</h2>

            <div className="ui left aligned sixteen wide column">
              <div style={textStyle}>
                <span>Email: </span>
                <a href="mailto: customerservice@dotlinedesign.com">customerservice@dotlinedesign.com</a>
              </div>
              <div style={textStyle}>Phone #: 1-905-760-1133</div>
            </div>
          {/* </div>
        </div> */}
      </div>

      <div className="ui hidden divider" />

      <div className="ui grid">
          <div className="left floated ten wide column">
            <div className="ui segment" >
              <iframe
                title="Dotline Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.133219012761!2d-79.36469157833602!3d43.83233563062885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2b3364ba1da5%3A0xfd0125d329792516!2sDot-Line%20Design%20Ltd!5e0!3m2!1sen!2sca!4v1732407655151!5m2!1sen!2sca"
                height="450"
                width="100%"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </div>
          </div>
          <div className="right floated left aligned six wide column">
            <div className="ui segment">
              <h2 className="ui header">Dotline Design</h2>
              <div style={textStyle}>255 Shields Court Unit C</div>
              <div style={textStyle}>Markham, ON L3R 8V2</div>
            </div>
          </div>
      </div>

      <div className="ui divider" />
    </div>
  )
};

export default connect(null, mapDispatchToProps)(ContactUsContainer);