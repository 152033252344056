import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { requestLogin, resetAuth } from '../actions/auth';
import { updateActivePage } from '../actions/header'
import LoginForm from '../components/LoginForm';

const mapDispatchToProps = dispatch => {
  return {
    requestLogin: (formData, token) => dispatch(requestLogin(formData, token)),
    updateActivePage: id => dispatch(updateActivePage(id)),
    resetAuth: () => dispatch(resetAuth())
  };
}

const mapStateToProps = state => {
  const selector = formValueSelector('LoginForm'); // <-- same as form name
  const { email, password } = selector(state, 'email', 'password');

  return {
    email,
    password,
    authPending: state.auth.pending,
    authError: state.auth.error
  };
}

const LoginContainer = (props) => {
  const {requestLogin, authPending, authError, updateActivePage, resetAuth } = props;

  useEffect(() => {
    updateActivePage(0);
    resetAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui centered middle aligned three column grid container">
      <div className="row">
        <div className="column">
          <LoginForm loading={authPending} authError={authError} onSubmit={requestLogin} />
          <div className="ui message">
            New to us? <a href="/signup">Sign Up</a>
          </div>
          <div className="ui message">
            Forgot your password? <a href="/forgotpassword">Reset Password</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
