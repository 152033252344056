import React from 'react';

const BlockedMessage = () => {
  return (
    <div className="ui container">
      <div className="ui warning message">
        <div className="header">
          Your account needs to be approved!
        </div>
        We will email you once your account is approved.
      </div>
    </div>
  );
};

export default BlockedMessage