import React from 'react';

const AccountDetail = ({ register, watch, errors }) => {
  const watchPassword = watch('password');

  return (
    <div>
      <div className="two fields">
        <div className={`field ${errors.firstname ? "error" : ""}`}>
          <label>First Name</label>
          <input name="firstname" type="text" placeholder="First Name" ref={register({ required: 'First name is required.' })} />
        </div>
        <div className={`field ${errors.lastname ? "error" : ""}`}>
          <label>Last Name</label>
          <input name="lastname" type="text" placeholder="Last Name" ref={register({ required: 'Last name is required.' })} />
        </div>
      </div>
      <div className={`field ${errors.companyname ? "error" : ""}`}>
        <label>Company Name</label>
        <div className="ui left icon input">
          <i className="building icon"></i>
          <input name="companyname" type="text" placeholder="Company Name" ref={register({ required: 'Company name is required.'})} />
        </div>
      </div>
      <div className="two fields">
        <div className={`field ${errors.gst_number ? "error" : ""}`}>
          <label>GST #</label>
          <input name="gst_number" type="text" placeholder="HST #" ref={register({ required: 'GST number is required.' })} />
        </div>
        <div className="field">
          <label>Years In Business</label>
          <input name="years_in_business" type="number" placeholder="Years in business" ref={register} />
        </div>
      </div>
      <div className={`field ${errors.email ? " error" : ""}`}>
        <label>Email</label>
        <div className="ui left icon input">
          <i className="user icon"></i>
          <input name="email" type="email" placeholder="E-mail address" ref={register({ required: 'Email required'})} />
        </div>
      </div>
      <div className="two fields">
        <div className={`field ${errors.password ? " error" : ""}`}>
          <label>Password</label>
          <div className="ui left icon input">
            <i className="lock icon"></i>
            <input
              name="password"
              type="password"
              placeholder="Password"
              ref={
                register({
                  required: 'Password required',
                  minLength: {
                    value: 6,
                    message: 'Password must be longer than 6 chracters'
                  }
                })
              }
            />
          </div>
        </div>

        <div className={`field ${errors.confirm_password ? " error" : ""}`}>
          <label>Confirm Password</label>
          <div className="ui left icon input">
            <i className="lock icon"></i>
            <input
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
              ref={
                register({
                  required: 'Password required',
                  minLength: {
                    value: 6,
                    message: 'Password must be longer than 6 chracters'
                  },
                  validate: {
                    valid: value => value === watchPassword || 'Passwords must match'
                  }
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="field">
        <label>Dotline Sales Rep</label>
        <input name="dotline_sales_rep" type="text" ref={register} />
      </div>
    </div>
  );
};

export default AccountDetail;