import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components';
import { Dropdown, Button, Image, Modal, Table, Grid } from 'semantic-ui-react'

import BlockedMessage from '../BlockedMessage';
import LoginMessage from '../LoginMessage';

const StyledImage = styled(Image)({
  objectFit: 'contain',
  height: '450px'
});

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ProductModal = ({product, addItem, modalOpen, setModalOpen, blocked, isLoggedIn}) => {
  const sizeOptions = _.map(product.sizes, size => ({
    key: size.id,
    text: size.size,
    value: size.size,
  }));

  const colorOptions = _.map(product.colors, color => ({
    key: color.id,
    text: color.color,
    value: color.color,
  }));

  const quantityOptions = _.map(
    Array.from(Array(20), (_, i) => ((i + 1) * product.quantity_per_pack).toString()),
    quantity => ({
      key: quantity,
      text: quantity,
      value: quantity
    })
  );

  const [selectedSize, setSelectedSize] = useState(sizeOptions[0]?.value);
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]?.value);
  const [selectedQuantity, setSelectedQuantity] = useState(quantityOptions[0]?.value);

  const handleSizeChange = (e, {value}) => {
    setSelectedSize(value);
  };

  const handleColorChange = (e, {value}) => {
    setSelectedColor(value);
  };

  const handleQuantityChange = (e, {value}) => {
    setSelectedQuantity(value);
  }

  const handleAddItem = (item) => {
    addItem(item);
    setModalOpen(false);
  }

  let selectedItem = {
    name: product.name,
    description: product.description,
    size: selectedSize,
    quantity: Number(selectedQuantity),
    price: product.price,
    color: selectedColor,
    url: product?.image?.url,
    quantityPerPack: product.quantity_per_pack
  };

  if (!isLoggedIn) {
    return (
      <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      size='small'
      closeIcon={true}
    >
        <Modal.Content>
          <LoginMessage />
        </Modal.Content>
      </Modal>
    );
  } else if (isLoggedIn && blocked) {
    return (
      <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      size='small'
      closeIcon={true}
    >
        <Modal.Content>
          <BlockedMessage />
        </Modal.Content>
      </Modal>
    );
  } else {
    return (
      <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      size='large'
      closeIcon={true}
    >
        <Modal.Header>{product.name} - {product.description}</Modal.Header>
        <Modal.Content>
          <Grid columns={2}>
            <Grid.Column>
              <StyledImage centered src={product?.image?.url} />
            </Grid.Column>
            <Grid.Column>
              <Table color='red' >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell><strong>Price</strong></Table.Cell>
                    <Table.Cell>{formatter.format(product.price)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><strong>Size</strong></Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        onChange={handleSizeChange}
                        selection
                        value={selectedSize}
                        options={sizeOptions}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><strong>Detail</strong></Table.Cell>
                    <Table.Cell>
                      {product.details.map((detail, index) => <span key={index}>{detail.detail}</span>)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><strong>Color</strong></Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        onChange={handleColorChange}
                        selection
                        value={selectedColor}
                        options={colorOptions}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><strong>Quantity</strong></Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        onChange={handleQuantityChange}
                        selection
                        scrolling
                        value={selectedQuantity}
                        options={quantityOptions}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table color='blue' celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='7' textAlign='center'>Socks Size Chart</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row textAlign='center'>
                    <Table.Cell></Table.Cell>
                    <Table.Cell colSpan='2'><b>Men</b></Table.Cell>
                    <Table.Cell><b>Ladies</b></Table.Cell>
                    <Table.Cell colSpan='3'><b>Kids</b></Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign='center'>
                    <Table.Cell><b>Sock</b></Table.Cell>
                    <Table.Cell>10-13</Table.Cell>
                    <Table.Cell>13-15</Table.Cell>
                    <Table.Cell>9-11</Table.Cell>
                    <Table.Cell>2-5</Table.Cell>
                    <Table.Cell>5-8</Table.Cell>
                    <Table.Cell>8-11</Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign='center'>
                    <Table.Cell><b>Shoe</b></Table.Cell>
                    <Table.Cell>8-12</Table.Cell>
                    <Table.Cell>12-16</Table.Cell>
                    <Table.Cell>6-9</Table.Cell>
                    <Table.Cell>5-9</Table.Cell>
                    <Table.Cell>8-13</Table.Cell>
                    <Table.Cell>13-14</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table color='green' celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='7' textAlign='center'>Underware Size Chart</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row textAlign='center'>
                    <Table.Cell></Table.Cell>
                    <Table.Cell><b>S/P</b></Table.Cell>
                    <Table.Cell><b>M/M</b></Table.Cell>
                    <Table.Cell><b>L/G</b></Table.Cell>
                    <Table.Cell><b>XL/TG</b></Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign='center'>
                    <Table.Cell><b>inch</b></Table.Cell>
                    <Table.Cell>28-30</Table.Cell>
                    <Table.Cell>32-34</Table.Cell>
                    <Table.Cell>36-38</Table.Cell>
                    <Table.Cell>40-42</Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign='center'>
                    <Table.Cell><b>cm</b></Table.Cell>
                    <Table.Cell>71-76</Table.Cell>
                    <Table.Cell>81-86</Table.Cell>
                    <Table.Cell>91-97</Table.Cell>
                    <Table.Cell>102-107</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid centered columns={2}>
            <Grid.Column>
              <Button primary fluid onClick={() => handleAddItem(selectedItem)}>
                ADD TO CART
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
};

export default ProductModal;