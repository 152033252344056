import React from 'react';

const LoginMessage = () => {
  return (
    <div className="ui container">
      <div className="ui warning message">
        <div className="header">
          Please login to see this content!
        </div>
      </div>
    </div>
  );
};

export default LoginMessage