import React, { useState, useEffect } from 'react';
import { Menu } from 'semantic-ui-react';

const BrandMenu = (props) => {
  const [selectedItem, setSelectedItem] = useState('');

  const { brands, onMenuChange, query } = props;

  const handleSelectedMenu = (event, target) => {
    setSelectedItem(target.name);
    onMenuChange(target.name);
  };

  useEffect(() => {
    if (query?.brand) {
      setSelectedItem(`brand.name=${query.brand}`);
    }
  }, [query]);

  return (
    <Menu vertical>
      {
        brands.map((brand, i) => {
          const brandFilter = `brand.name=${brand.name}`
          return (
            <div key={i}>
              <Menu.Item
                name={brandFilter}
                active={selectedItem === brandFilter}
                onClick={handleSelectedMenu}
                style={{fontSize: '1.2em'}}
              >
                {brand.name}
              </Menu.Item>
              <Menu.Item>
                <Menu.Menu>
                {
                  brand.categories.map((category, j) => {
                    const categoryFilter = `brand.name=${brand.name}&category.name=${category.name}`;
                    return (
                      <Menu.Item
                        name={categoryFilter}
                        active={selectedItem === categoryFilter}
                        onClick={handleSelectedMenu}
                        style={{fontSize: '1.1em'}}
                        key={j}
                      >
                        {category.name}
                      </Menu.Item>
                    );
                  })
                }
                </Menu.Menu>
              </Menu.Item>
            </div>
          );
        })
      }
    </Menu>
  );
};

export default BrandMenu;