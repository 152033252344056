import React from 'react';

const ShippingAddress = ({ register, errors }) => {

  return (
    <div>
      <div className="ui hidden divider" />
      <h4 className="ui horizontal divider header">Shipping Address</h4>
      <div className={`field ${errors.shipping_street ? " error" : ""}`}>
        <label>Street Address</label>
        <input name="shipping_street" type="text" placeholder="123 Example Rd." ref={register({ required: 'Shipping address is required.' })} />
      </div>
      <div className="three fields">
        <div className={`field ${errors.shipping_city ? " error" : ""}`}>
          <label>City</label>
          <div className="ui left input">
            <input name="shipping_city" type="text" placeholder="City" ref={register({ required: 'Shipping city required.' })} />
          </div>
        </div>
        <div className={`field ${errors.shipping_province ? " error" : ""}`}>
          <label>Province</label>
          <div className="ui left input">
            <input name="shipping_province" type="text" placeholder="Province" ref={register({ required: 'Shipping province required.' })} />
          </div>
        </div>
        <div className={`field ${errors.shipping_postal_code ? " error" : ""}`}>
          <label>Postal Code</label>
          <div className="ui left input">
            <input name="shipping_postal_code" type="text" placeholder="Postal Code" ref={register({ required: 'Shipping postal code required.' })} />
          </div>
        </div>
      </div>
      <div className="two fields">
        <div className={`field ${errors.shipping_phone_number ? " error" : ""}`}>
          <label>Phone #</label>
          <div className="ui left input">
            <input name="shipping_phone_number" type="text" placeholder="Phone #" ref={register({ required: 'Shipping phone # required.' })} />
          </div>
        </div>
        <div className={`field ${errors.shipping_fax_number ? " error" : ""}`}>
          <label>Fax #</label>
          <div className="ui left input">
            <input name="shipping_fax_number" type="text" placeholder="Fax #" ref={register} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;