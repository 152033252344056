import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = props => {
  const { isLoggedIn, type, blocked } = props;

  if (type === "guest" && isLoggedIn) return <Redirect to="/" />;
  if (type === "private" && !isLoggedIn) return <Redirect to="/login" />;
  if (type === "private" && isLoggedIn && blocked) return <Redirect to="/blocked" />;

  return <Route {...props} />;
};

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: auth.isLoggedIn,
  blocked: auth.blocked
});

export default connect(mapStateToProps)(AuthRoute);