import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components'

const SliderWrap = styled.div`
    .slick-slider {
        .slick-list {
            .slick-track {
                .slick-slide img {
                    height: 40vh;
                    object-fit: contain;
                }
            }
        }
    }
`

const leftArrowStyle = {
  position: 'absolute',
  top: '45%',
  left: '-50px',
  backgroundColor: 'transparent'
};

const rightArrowStyle = {
  position: 'absolute',
  top: '45%',
  right: '-50px',
  backgroundColor: 'transparent'
}

const SliderArrow = (props) => {
  const { direction, onClick } = props;
  return (
    <div style={direction === 'right' ? rightArrowStyle : leftArrowStyle} className={`ui icon button`} onClick={onClick} >
      <i className={`big chevron ${direction} icon`} />
    </div>
  );
};

const BannerCarousel = ({ images }) => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />
  };

  return (
    <SliderWrap>
      <Slider {...settings}>
        {
          images.map((image, i) => (
            <img key={i} src={image.url} alt="BANNER" />
          ))
        }
      </Slider>
    </SliderWrap>
  );
};

export default BannerCarousel;