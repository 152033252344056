import type from '../types';

const initialStatePage = {
  id: 1
}

export const activePage = (state=initialStatePage, action={}) => {
  switch(action.type) {
    case type.UPDATE_ACTIVE_PAGE:
      return {...state, id: action.id };
    default:
      return state;
  }
};