import React from 'react';
import CartModal from './Modal';

import { Header, Icon, Grid } from 'semantic-ui-react'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CartHeader = ({user, cart, sendOrder, resetCart}) => {
  return (
    <Grid verticalAlign='middle' columns={3}>
      <Grid.Column>
        <Header as="h2">
          <Icon name="shopping cart" />
          <Header.Content>Your Cart</Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header as="h2">
          <Header.Content>Current Total: {formatter.format(cart.total)}</Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column textAlign='right'>
        <CartModal user={user} items={cart.items} cart={cart} sendOrder={sendOrder} resetCart={resetCart} />
      </Grid.Column>
    </Grid>
  );
}

export default CartHeader;