import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logout } from '../actions/auth'

const buttonStyle = {
  marginLeft: '0.5em'
}

const mapStateToProps = state => {
  return {
    activePage: state.activePage.id,
    isLoggedIn: state.auth.isLoggedIn,
    cartItemsCount: state.cart.items.length
  };
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  }
}

class HeaderContainer extends Component {
  render() {
    const { isLoggedIn, activePage, cartItemsCount, logout } = this.props;

    return isLoggedIn ?
    (
      <div>
        <div className="ui inverted vertical masthead center aligned segment">
          <div className="ui container">
            <div className="ui large secondary inverted pointing menu">
              <div className="left item">
                <a className={activePage === 1 ? "item active" : "item"} href="/"><h3>Home</h3></a>
                <a className={activePage === 2 ? "item active" : "item"} href="/products"><h3>Products</h3></a>
                <a className={activePage === 3 ? "item active" : "item"} href="/brands"><h3>Brands</h3></a>
                <a className={activePage === 4 ? "item active" : "item"} href="/catalogue"><h3>Catalogue</h3></a>
                <a className={activePage === 5 ? "item active" : "item"} href="/contactus"><h3>Contact Us</h3></a>
              </div>
              <div className="right item">
                <h3 className="ui middle aligned item">Wholesale Only</h3>
                {/* <div className="item">
                  <a href="/profile">
                    <i className="user icon"></i>
                    <span>My Profile</span>
                  </a>
                </div> */}
                <div className="item">
                  <a href="/cart">
                    <i className="shopping cart icon"></i>
                    <span>{cartItemsCount}</span>
                  </a>
                </div>
                <div className="item">
                  <button className="ui inverted button" style={buttonStyle} onClick={() => logout()}>Logout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui hidden divider" />
      </div>
    ) :
    (
      <div>
        <div className="ui inverted vertical masthead center aligned segment">
          <div className="ui container">
            <div className="ui large secondary inverted pointing menu">
              <div className="left item">
                <a className={activePage === 1 ? "item active" : "item"} href="/"><h3>Home</h3></a>
                <a className={activePage === 2 ? "item active" : "item"} href="/products"><h3>Products</h3></a>
                <a className={activePage === 3 ? "item active" : "item"} href="/brands"><h3>Brands</h3></a>
                <a className={activePage === 4 ? "item active" : "item"} href="/catalogue"><h3>Catalogue</h3></a>
                <a className={activePage === 5 ? "item active" : "item"} href="/contactus"><h3>Contact Us</h3></a>
              </div>
              <div className="right item">
                <h3 className="ui middle aligned item">Wholesale Only</h3>
                <a className="ui inverted button" style={buttonStyle} href="/login">Log in</a>
                <a className="ui inverted button" style={buttonStyle} href="/signup">Sign Up</a>
              </div>
            </div>
          </div>
        </div>
        <div className="ui hidden divider" />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
