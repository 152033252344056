import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { updateActivePage } from '../actions/header';

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    blocked: state.auth.blocked,
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: (index) => dispatch(updateActivePage(index))
  };
};

const ProfileContainer = (props) => {
  const {user, updateActivePage } = props;

  useEffect(() => {
    updateActivePage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui container">
      {user.email}
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);