import React from 'react';

const imageStyle = {
  height: '200px',
  objectFit: 'contain',
  backgroundColor: 'white'
}

const Card = ({ catalogue }) => {
  return (
    <div className="ui card">
      <div className="ui fluid centered image">
        <img src={catalogue.image.url} alt="catalogue" style={imageStyle}/>
      </div>
      <div className="center aligned content">
        <div className="header">
          <a href={catalogue?.file?.url} target="_blank" download rel="noopener noreferrer">{catalogue.name}</a>
        </div>
      </div>
    </div>
  );
};

export default Card;